import "./App.css";
import React, { useState, useEffect, useCallback } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import questions from "./questions.json";

const customStyles = {
  overlay: { zIndex: 1000 },
};

const QuestionCircles = ({ selectedCircles, handleCircleClick, type }) => {
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selection, setSelection] = useState({});
  const [modalText, setModalText] = useState("");
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const saveSelection = useCallback(() => {
    const data = {
      name: selection.name,
      id: selection.id,
      text: modalText,
    };
    handleCircleClick(data, selection.circleType, type);
    setIsModalOpen(false);
  }, [selection, modalText, handleCircleClick, type]);

  useEffect(() => {
    if (modalText === "" && isDeleteClicked) {
      saveSelection();
      setIsDeleteClicked(false);
    }
  }, [modalText, isDeleteClicked, saveSelection]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    setIsAtTop(scrollTop < 100);
    setIsAtBottom(scrollTop + clientHeight > scrollHeight - 50);
  };

  function selectionHandler({ id, circleType, ...circle }) {
    setSelection({ name: questions[type][id].name, id, circleType });
    if (circleType === "modal") {
      const text = selectedCircles.find((item) => item.id === id)?.text || "";
      setModalText(text || "");
      setIsModalOpen(true);
      return;
    }
    handleCircleClick({ id, ...circle }, circleType, type);
  }

  const circles = questions[type];

  const title = type === "assets" ? "Voimauttajat" : "Kuormittajat";

  return (
    <div className="flexColumnCenter">
      {!isAtTop && (
        <div className="arrow up">
          <IoIosArrowUp
            size={30}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          />
        </div>
      )}
      {!isAtBottom && (
        <div className="arrow down">
          <IoIosArrowDown
            size={30}
            onClick={() =>
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
              })
            }
          />
        </div>
      )}
      <h1 className="common-header">{title}</h1>
      <div className="flexWrapCenter">
        {Object.entries(circles).map(([id, circle, circleType]) => (
          <div
            key={id}
            className={`circle ${type} ${
              selectedCircles.some((selectedCircle) => selectedCircle.id === id)
                ? "selected"
                : ""
            }`}
            onClick={() => selectionHandler({ id, circleType, ...circle })}
          >
            {circle.name}
          </div>
        ))}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          ariaHideApp={false}
          style={customStyles}
        >
          <IoClose
            size={30}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={() => setIsModalOpen(false)}
          />
          <div className="modal-content">
            <h2 className="common-header">{selection.name}</h2>
            <textarea
              rows="10"
              cols="100"
              onChange={(e) => setModalText(e.target.value)}
              value={modalText}
              style={{ width: "100%" }}
            />
            <div className="button-container">
              <button className="button" onClick={() => saveSelection()}>
                Tallenna
              </button>
              <button
                className="delete-button"
                onClick={() => {
                  setModalText("");
                  setIsDeleteClicked(true);
                  setIsModalOpen(false);
                }}
              >
                Tyhjennä ja sulje
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default QuestionCircles;
